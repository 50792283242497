
import { computed, defineComponent, onMounted, ref } from "vue";
import Entities from "@/components/base/common/Entities.vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import { ISearchField } from "@/core/entity/ISearchField";
import {CASE_STATUS, CLAIM_DECISION, CLAIM_DOC_STATUS, OPERATOR} from "@/core/config/Constant";
import {
  CompanySelect,
  ExaminerSelect,
  LoadPanel,
  PPOSelect,
  SearchEntityEvent,
  TagSelect
} from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import Cards from "@/components/base/common/Cards.vue";
import store from "@/store";
import ClaimViewCard from "@/views/claim/ClaimViewCard.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "Claims",
  components: {BaseSelect, KtDatatable, DateTimeFormat, ClaimViewCard, Cards, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Claim Search',
        [
          {link: false, router: '', text: 'Claim Search'}
        ]
      )
    })
    const fields = ['all']
    const fieldSearch: ISearchField[] = [
      {column: 'clientId', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Client'},
      {column: 'policyNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Policy #'},
      {column: 'certificate', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Certificate #'},
      {column: 'claimNumber', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Claim #'},
      {column: 'networkId', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Network'},
      {column: 'refNum', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Reference#'},
      {column: 'firstName', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'First Name'},
      {column: 'lastName', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Last Name'},
      {column: 'claimStatus', type: 'SELECT', operator: OPERATOR.EQ, value: 'Active', name: 'Status'},
      {column: 'docStatus', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Doc status'},
      {column: 'decisionStatus', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Decision status'},
      {column: 'examinerId', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'Examiner'},
      {column: 'tags', type: 'TEXT', operator: OPERATOR.LIKE, value: '', name: 'Tag'},
      {column: 'ghipStatus', type: 'SELECT', operator: OPERATOR.EQ, value: '', name: 'GHIP Status'},
    ]
    const tableHeader = ref([
      {
        name: "Claim#",
        key: "claimNumber",
        sortable: true,
      },
      {
        name: "Client",
        key: "client",
        sortable: true,
      },
      {
        name: "Policy#",
        key: "policyNumber",
        sortable: true,
      },
      {
        name: "Case#",
        key: "cazeNumber",
        sortable: true,
      },
      {
        name: "insured",
        key: "insured",
        sortable: true,
      },
      {
        name: "DOB",
        key: "dob",
        sortable: true,
      },
      {
        name: "Status",
        key: "claimStatus",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },

    ]);
    const claimSearchPage = computed(() => store.state.ClaimModule.claimSearchPage)
    return {
      fields,
      fieldSearch,
      tableHeader,
      ...CompanySelect(),
      claimSearchPage,
      ...SearchEntityEvent(Actions.CLAIM_SEARCH, fieldSearch, fields, [], 'claimView'),
      ...LoadPanel(),
      ...ExaminerSelect(),
      ...PPOSelect(),
      CASE_STATUS,
      CLAIM_DOC_STATUS,
      CLAIM_DECISION,
      ...TagSelect('CLAIM'),
    }
  }
})
